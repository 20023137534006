import React from 'react'
import { useIntl } from 'gatsby-plugin-intl';

import Header from '../components/header'
import Footer from '../components/footer'
import Graphic from '../components/graphic/graphic'
import headerServicesHr from '../slider/header_usluge.jpg';
import headerServicesDe from '../slider/header_usluge_DE.jpg';
import favicon from '../icons/favicon.ico';
import { Helmet } from "react-helmet";

export default (props) => {
    const intl = useIntl();
    const lang = props.pathContext.language;

    return (
        <div className="services-page">
            <Helmet>
                <meta property="og:image" content="https://nanonet.hr/cover.jpg"/>
                <meta property="og:image:secure_url" content="https://nanonet.hr/cover.jpg"/>
                <title>{intl.formatMessage({ id: "header.services" })}</title>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Header lang={lang}/>

            <div className="desktop">
                <img src={lang === 'de' ? headerServicesDe : headerServicesHr} style={{width: '100%'}} alt="" />
            </div>

            <div style={{margin: '0 10%'}}>
                <div className="process-v3">
                    <div className="text main-title">{intl.formatMessage({ id: "servicesPage.production.title" })}</div>
                    <div className="steps-container">
                        <div className="step-row">
                            <div className="step">
                                <div className="text-container">
                                    <div className="text title">
                                        {intl.formatMessage({ id: "servicesPage.production.step1.title" })}
                                    </div>
                                    <div className="text description">
                                        {intl.formatMessage({ id: "servicesPage.production.step1.description" })}
                                    </div>
                                </div>
                            </div>
                            <div className="step step-desktop">
                                <div className="text-container">
                                    <div className="text title">
                                        {intl.formatMessage({ id: "servicesPage.production.step3.title" })}
                                    </div>
                                    <div className="text description">
                                        {intl.formatMessage({ id: "servicesPage.production.step3.description" })}
                                    </div>
                                </div>
                            </div>
                            <div className="step step-mobile">
                                <div className="text-container">
                                    <div className="text title">
                                        {intl.formatMessage({ id: "servicesPage.production.step2.title" })}
                                    </div>
                                    <div className="text description">
                                        {intl.formatMessage({ id: "servicesPage.production.step2.description" })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="step-row">
                            <div className="step step-desktop">
                                <div className="text-container">
                                    <div className="text title">
                                        {intl.formatMessage({ id: "servicesPage.production.step2.title" })}
                                    </div>
                                    <div className="text description">
                                        {intl.formatMessage({ id: "servicesPage.production.step2.description" })}
                                    </div>
                                </div>
                            </div>
                            <div className="step step-mobile">
                                <div className="text-container">
                                    <div className="text title">
                                        {intl.formatMessage({ id: "servicesPage.production.step3.title" })}
                                    </div>
                                    <div className="text description">
                                        {intl.formatMessage({ id: "servicesPage.production.step3.description" })}
                                    </div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="text-container">
                                    <div className="text title">
                                        {intl.formatMessage({ id: "servicesPage.production.step4.title" })}
                                    </div>
                                    <div className="text description">
                                        {intl.formatMessage({ id: "servicesPage.production.step4.description" })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Graphic />

            <div style={{margin: '0 10%'}}>
                <div className="process-v3">
                    <div className="text main-title">{intl.formatMessage({ id: "servicesPage.pricingAndService.title" })}</div>
                    <div className="steps-container">
                        <div className="step-row">
                            <div className="step">
                                <div className="text-container">
                                    <div className="text title" style={{color: 'black'}}>
                                        {intl.formatMessage({ id: "servicesPage.pricingAndService.services.title" })}
                                    </div>
                                    <ul className="text description regular-ul">
                                        <li>{intl.formatMessage({ id: "servicesPage.pricingAndService.services.line1" })}</li>
                                        <li>{intl.formatMessage({ id: "servicesPage.pricingAndService.services.line2" })}</li>
                                        <li>{intl.formatMessage({ id: "servicesPage.pricingAndService.services.line3" })}</li>
                                        <li>{intl.formatMessage({ id: "servicesPage.pricingAndService.services.line4" })}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
